import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {navigate, Link} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';
import {useOutlet, useOutletSetter} from 'reconnect.js';
import useBreakpoint from '../../Hooks/useBreakPoint';

function Team(props) {
  const [actions] = useOutlet('actions');
  const [dimension] = useOutlet('dimension');
  const [articles, setArticles] = React.useState([]);
  const [banner, setBanner] = React.useState('');

  const [bannerDimension, setBannerDimension] = React.useState(null);

  React.useLayoutEffect(() => {
    const elem = document.getElementById('rev-banner');
    const box = elem.getBoundingClientRect();
    setBannerDimension(box);
  }, [dimension.innerWidth]);

  React.useEffect(() => {
    async function fetchArticleData() {
      try {
        actions.setLoading(true);
        let resp = await actions.fetchDocuments('Article_Default', {
          label: ['醫生團隊'],
        });
        let dataList = resp.results.map((it) => {
          if (!it.id && it._id) {
            it.id = it._id.$oid;
          }
          return {...it};
        });
        setArticles(dataList);
      } catch (ex) {
        console.warn(ex);
      } finally {
        actions.setLoading(false);
      }
    }
    async function fetchBanner() {
      actions.setLoading(true);
      let bannerList = await actions.fetchDocuments('banner');
      bannerList = bannerList.results;
      let _banner = await bannerList.filter(
        (banner) => banner.type === 'team',
      )[0];
      actions.setLoading(false);
      setBanner(_banner.image);
    }
    fetchBanner();

    fetchArticleData();
  }, [actions]);

  let itemWidth =
    dimension.rwd === 'mobile'
      ? (dimension.innerWidth - 40 - 20) / 2
      : (1240 - 100 - 60) / 4;

  return (
    <Wrapper>
      <div
        id="rev-banner"
        className="banner"
        style={{height: bannerDimension?.width * 0.38}}>
        <img
          src={banner}
          style={{width: '100%', height: '100%', objectFit: 'cover'}}
        />
      </div>
      <section className="section">
        <div className="subtitle">TEAM</div>
        <div className="title">醫生團隊</div>

        <p className="content" style={{marginTop: 20}}>
          {`專業技術精準展現，實現專屬質感美
專業醫師團隊用豐富的美學經驗，權威認證技術與細心的服務態度，共同量身打造專屬於你的美麗與自信。`}
        </p>

        <div className="grid" style={{flexDirection: 'row'}}>
          {articles.map((item, idx) => (
            <div
              onClick={() => {
                navigate(`/member?id=${item.id}`);
              }}
              className="grid-item"
              key={idx}
              style={{
                width: itemWidth,
                marginBottom: 50,
              }}>
              <div
                className="grid-item-img "
                style={{
                  backgroundImage: `url(${item.image})`,
                  height: (itemWidth * 353) / 295,
                }}
              />
              <p
                className="grid-item-title"
                style={{marginTop: 10, color: '#434343'}}>
                {item.title}
              </p>
              <p className="grid-item-outline" style={{marginTop: 10}}>
                {item.outline}
              </p>
            </div>
          ))}
          {[0, 1, 2].map((item) => (
            <div
              className="grid-item"
              style={{
                width: itemWidth,
              }}></div>
          ))}
        </div>
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .banner {
    width: 100%;
    height: 600px;
    /* position: relative; */
    /* background-color: var(--primaryColor); */
  }
`;

export default Team;
